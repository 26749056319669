<template>
    <div id="cv-page">
        <b-container id="container-md">
            <b-container id="container-sm" class="cv-section">
                <b-row>
                    <b-col class="experience">
                        <b-row>
                            <b-container class="xp-title">
                                <h3>Experience</h3>
                            </b-container>
                        </b-row>
                        <b-row class="xp-item">
                            <b-col class="timeline">
                                <h4>OCT 2021 - Current</h4>
                                <p>Experis Norway</p>
                            </b-col>
                            <b-col class="divider"></b-col>
                            <b-col class="xp-desc">
                                <h4>Business Consultant</h4>
                                <p>I'm working closely with customers and businesses to tap their fullest potential through 
                                    technology, people, organization, and transformation. Profound experience with IT infrastructure
                                     and a firm believer that it all starts with people and employees. Advocates agile mindset, 
                                     design thinking, change management, and system thinking by understanding the overarching context</p>
                            </b-col>
                        </b-row>
                        <b-row class="xp-item">
                            <b-col class="timeline">
                                <h4>Nov 2020 - Oct 2021</h4>
                                <p>DNB Bank ASA</p>
                            </b-col>
                            <b-col class="divider"></b-col>
                            <b-col class="xp-desc">
                                <h4>Tech Lead</h4>
                                <p>Tech Lead for Connectivity Infrastructure in DNB. Leading a team of 13 engineers and architects. 
                                    Responsible for team capacity, ensuring the smooth functioning of technical operations, monitoring and evaluating team progress, 
                                    assisting with training and recruitment, setting goals, and ensuring overall client satisfaction. Meanwhile, keeping myself 
                                    up-to-date with relevant technology and still performing changes and technical assessments. </p>
                            </b-col>
                        </b-row>
                        <b-row class="xp-item">
                            <b-col class="timeline">
                                <h4>Apr 2018 - Nov 2020</h4>
                                <p>DNB Bank ASA</p>
                            </b-col>
                            <b-col class="divider"></b-col>
                            <b-col class="xp-desc">
                                <h4>Infrastructure Engineer</h4>
                                <p>Infrastructure Engineer in DNB, Norway's largest financial services group. Responsible for ensuring stable and secure operations 
                                    of nationally critical  IT infrastructure. Daily tasks include mapping user requirements, designing new infrastructure solutions 
                                    and components, implementing infrastructure changes, and operating the infrastructure environment. </p>
                            </b-col>
                        </b-row>
                        <b-row class="xp-item">
                            <b-col class="timeline">
                                <h4>Aug 2014 - Apr 2018</h4>
                                <p>Communication and Information Systems - Task Group (CIS TG)</p>
                            </b-col>
                            <b-col class="divider"></b-col>
                            <b-col class="xp-desc">
                                <h4>System Engineer</h4>
                                <p>System Engineer in the Norwegian Armed Forces Cyber Defence. Responsible for deploying and operating communication systems and 
                                    IT infrastructure on behalf of military units spanning all of the domains in the Armed Forces. Five international deployments 
                                    as system engineer; 1 time to Erbil, Iraq, and four times to Kabul, Afghanistan. </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-container class="xp-title">
                                <h3>Education</h3>
                            </b-container>
                        </b-row>
                        <b-row class="xp-item">
                            <b-col class="timeline">
                                <h4>Aug 2018 - Dec 2021</h4>
                                <p>University at Stavanger</p>
                            </b-col>
                            <b-col class="divider"></b-col>
                            <b-col class="xp-desc">
                                <h4>Part Time Student</h4>
                                <p>Master in Science in Industrial Economics. Combines technology, management, and economics, which are vital areas in the modern 
                                    cross-disciplinary field of technology management. Computer science and project management as specialization. 100% sidetrack from work.</p>
                            </b-col>
                        </b-row>
                        <b-row class="xp-item">
                            <b-col class="timeline">
                                <h4>Jul 2011 - Aug 2014</h4>
                                <p>Norwegian Defence Cyber Academy</p>
                            </b-col>
                            <b-col class="divider"></b-col>
                            <b-col class="xp-desc">
                                <h4>Full Time Student</h4>
                                <p>Bachelor's Degree in Telematics, covering several areas like cybersecurity, information security, communication networks, 
                                    and networked services. The academy also provided military leadership training, both theoretical and practical. 
                                    Every year, multiple military exercises were conducted to test both our academic and military leadership skills</p>
                            </b-col>
                        </b-row>
                        <b-container class="skills skills-row">
                        <!-- <b-row class="skills skills-row"> -->
                            <b-container class="xp-title">
                                <br>    
                                <h3>Skills</h3>
                            </b-container>
                            <b-row>
                                <b-container class="skills-title">Programming</b-container>
                                <b-container>
                                    <ul class="skills">
                                        <li>Python</li>
                                        <li>Golang</li>
                                        <li>HTML/CSS/JS</li>
                                        <li>VUEJS</li>
                                        <li>SQL</li>
                                    </ul>
                                </b-container>
                            </b-row>
                            <b-row>
                                <b-container class="skills-title">Architecture</b-container>
                                <b-container>
                                    <ul class="skills">
                                        <li>Enterprise Infrastructure</li>
                                        <li>Networking and Network Security</li>
                                        <li>Azure</li>
                                        <li>Azure Stack</li>
                                        <li>AWS</li>
                                        <li>GCP</li>
                                    </ul>
                                </b-container>
                            </b-row>
                            <b-row>
                                <b-container class="skills-title">Certifications</b-container>
                                <b-container>
                                    <ul class="skills">
                                        <li>Cisco CCENT</li>
                                        <li>ITIL Foundation</li>
                                    </ul>
                                </b-container>
                            </b-row>
                            <b-row>
                                <b-container class="skills-title">Other</b-container>
                                <b-container>
                                    <ul class="skills">
                                        <li>Communication</li>
                                        <li>Technical Leadership</li>
                                        <li>Coordination</li>
                                        <li>Planning</li>
                                        <li>Agile, Scrum and Kanban</li>
                                    </ul>
                                </b-container>
                            </b-row>
                        <!-- </b-row> -->
                        </b-container>
                    </b-col>
                    <b-col class="skills skills-column">
                        <b-row>
                            <b-container class="skills-title">Programming</b-container>
                            <b-container>
                                <ul class="skills">
                                    <li><a href="https://www.terraform.io/" target="_blank">Terraform</a></li>
                                    <li><a href="https://www.python.org/" target="_blank">Python</a></li>
                                    <li><a href="https://go.dev/" target="_blank">Golang</a></li>
                                    <li><a href="https://www.w3schools.com/whatis/" target="_blank">HTML/CSS/JS</a></li>
                                    <li><a href="https://vuejs.org/" target="_blank">VUEJS</a></li>
                                    <li><a href="https://www.postgresql.org/" target="_blank">PostgreSQL</a></li>
                                </ul>
                            </b-container>
                        </b-row>
                        <b-row>
                            <b-container class="skills-title">Architecture</b-container>
                            <b-container>
                                <ul class="skills">
                                    <li><a href="https://en.wikipedia.org/wiki/Enterprise_architecture" target="_blank">Enterprise Architecture</a></li>
                                    <li><a href="https://en.wikipedia.org/wiki/IT_infrastructure" target="_blank">Enterprise Infrastructure</a></li>
                                    <li>Networking and Network Security</li>
                                    <li><a href="https://azure.microsoft.com/en-us/" target="_blank">Azure</a></li>
                                    <li><a href="https://aws.amazon.com/" target="_blank">AWS</a></li>
                                    <li><a href="https://cloud.google.com/" target="_blank">GCP</a></li>
                                    <li><a href="https://restfulapi.net/" target="_blank">REST API</a></li>
                                </ul>
                            </b-container>
                        </b-row>
                        <b-row>
                            <b-container class="skills-title">Certifications</b-container>
                            <b-container>
                                <ul class="skills">
                                    <li>Cisco CCENT</li>
                                    <li>ITIL Foundation</li>
                                </ul>
                            </b-container>
                        </b-row>
                        <b-row>
                            <b-container class="skills-title">Other</b-container>
                            <b-container>
                                <ul class="skills">
                                    <li>Communication</li>
                                    <li>Technical Leadership</li>
                                    <li>Coordination</li>
                                    <li>Planning</li>
                                    <li><a href="https://agilemanifesto.org/" target="_blank">Agile</a></li>
                                    <li>Scrum</li>
                                    <li>Kanban</li>
                                    <li>Design Thinking</li>
                                    <li>Change Management</li>
                                    <li>Digital Transformation</li>
                                </ul>
                            </b-container>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto:400,900);
#cv-page {
    //padding: 30px 100px; --> PC/Computers  screens
    background-color: #002c3d;
    padding: 0;
    margin: 0;
}

#container-md {
    padding: 0;
    //margin: 0;
}


.cv-section {
    height: 100%;
    width: 100%;
    background-color: rgb(34, 34, 34);
    min-height: 50vh;
    color: white;
    //border-radius: 70px;
    border-radius: initial;
}

.col {
    padding: 39px 10px;
}

.experience {
    & h4 {
        font-size: 14px;
        font-weight: 900;
        color: #a5a6a7;
        text-transform: uppercase;
    }
    & p {
        font-size: 13px;
        font-weight: 400;
        color: #a5a6a7;
        padding: 15px 0px;
    }
}

.xp-title {
    color: #04b4e0;
    font-size: 25px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    padding: 20px 10px;
}



.divider {
    -ms-flex: 0 0 1px;
    flex: 0 0 1px;
    padding: 0;
    background-color: white;
}

.skills {
    & li {
        display: inline-block;
        background-color: #04b4e0;
        border-radius: 3px;
        padding: 1px 10px;
        margin: 3px 2px;
        font-size: 13px;
        font-weight: 500;  
    }
}

.skills-title {
    color: #a5a6a7;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    padding: 10px 10px;
}

ul, ol {
    list-style: none;
    padding: 0;
}

.skills-column {
    display: none;
}

// @media only screen and (max-width: 600px) {
//   #cv-page, #container-md {
//       padding: 0;
//       margin: 0;
//   }
//   .cv-section {
//       border-radius: initial;
//   }
// }

// @media only screen and (max-width: 800px) {
//   #cv-page {
//       padding: 0;
//       margin: 0;
//   }
// }

@media (min-width: 992px) {
    #cv-page {
        padding: 30px 100px;
        margin: auto;
    }
}

@media  (min-width: 768px) {
    #cv-page {
        padding: 30px 100px;
        margin: auto;
    }

    .cv-section {
        border-radius: 70px;
        width: 100%;
    }

    .skills-row {
        display: none;
    }

    .skills-column {
        display: block;
    }
}

@media (min-width: 576px) {
    .cv-section {
        border-radius: 70px;
        width: 100%;
    }
}

</style>