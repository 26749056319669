<template>
  <div>
      <h1>BLOG</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  created() {
    window.location.href = "https://blog.tordlangedalness.com";
  },
}
</script>
